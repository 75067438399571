import React from "react";
import Styles from "../styles/Styles";

import { Icons, Texts } from "../../../../config";
import { connect } from "react-redux";
import { Grid, IconButton } from "@material-ui/core";
import { Creators } from "../reduxSagas";
import {
  ButtonContained,
  ButtonText,
  CustomText,
  Input,
  InputDate,
  Pagination,
  Select,
  Table,
} from "../../../../components";
import fonts from "../../../../config/fonts";

import { Filters, PasswordModal } from "../components";
import filters from "../../../../lib/Filters";
import DropdownActionSelect from "../../../../components/buttons/DropdownActionSelect";
import { Check, Close, Edit, Launch } from "@material-ui/icons";
import { customModal } from "../../../modals/utils";

import { FormHolder } from "../../../../FormConfig";
import { Validations } from "../../../../lib";
import EditModal from "../components/EditModal";
const placeholderList = [
  {
    createAt: new Date(),
    name: "Fulano de Tal",
    checkIn: new Date(),
    checkOut: new Date(),
    code: "KSA1231",
    guestData: {},
    id: 1,
    email: "email@exemplo.com",
    phone: "469990022",
  },
  {
    createAt: new Date(),
    name: "Fulano de Tal",
    checkIn: new Date(),
    checkOut: new Date(),
    code: "KSA1231",
    guestData: null,
    id: 3,
    email: "email@exemplo.com",
    phone: "469990022",
  },
  {
    createAt: new Date(),
    name: "Fulano de Tal",
    checkIn: new Date(),
    checkOut: new Date(),
    code: "KSA1231",
    guestData: {},
    id: 1,
    email: "email@exemplo.com",
    phone: "469990022",
  },
  {
    createAt: new Date(),
    name: "Fulano de Tal",
    checkIn: new Date(),
    checkOut: new Date(),
    code: "KSA1231",
    guestData: {},
    id: 2,
    email: "email@exemplo.com",
    phone: "469990022",
  },
];
const pageSize = 10;
function Container({
  reservationsRequest,
  isFetching,
  requestedReservationsData,
  lastFilter,
}) {
  const text = Texts["pt-BR"].beHome;
  const [page, setPage] = React.useState(1);

  const onMount = React.useCallback(() => {
    reservationsRequest({ page: 0, pageSize: pageSize });
  }, []);

  React.useEffect(onMount, [onMount]);

  function submitFilter(data) {
    setPage(1);
    reservationsRequest({
      page: 0,
      pageSize: pageSize,
      ...data,
      reservationConfirmed:
        data?.reservationConfirmed !== "all"
          ? data?.reservationConfirmed
          : null,
      checkOutDate:
        data?.checkOutDate && filters.dataToYYYYMMDD(data?.checkOutDate),
      checkInDate:
        data?.checkInDate && filters.dataToYYYYMMDD(data?.checkInDate),
      createdAt: data?.createdAt && filters.dataToYYYYMMDD(data?.createdAt),
    });
  }

  function changePage(page) {
    setPage(page);
    reservationsRequest({ ...lastFilter, page: page - 1 });
  }

  function putDoorKey(data) {
    customModal.setInfos(
      null,
      "Gerar Senha de Fechadura",
      ["Edite e confirme os dados dos hóspede para gerar uma nova senha:"],
      null,
      null,

      <Styles.Modal>
        <FormHolder
          onSubmit={(data) => {
            customModal.close();
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Input
                disabled
                readOnly
                defaultValue={data?.name}
                name="name"
                inputLabel="Nome do Hóspede"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                disabled
                readOnly
                defaultValue={data?.phone}
                read="phone"
                inputLabel="Telefone"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                disabled
                readOnly
                defaultValue={data?.email}
                name="email"
                inputLabel="E-mail"
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <Input
                disabled
                readOnly
                defaultValue={filters.fixDateToLabel(data.checkIn)}
                name="checkIn"
                inputLabel="Check-in"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Input
                disabled
                readOnly
                defaultValue={filters.fixDateToLabel(data.checkOut)}
                name="checkOut"
                inputLabel="Check-out"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Select
                required="*Campo Obrigatório"
                //defaultValue={data?.name}
                name="room"
                inputLabel="Seleção do Studio"
                options={[
                  { label: "SH700", value: "SH700" },
                  { label: "SH701", value: "SH701" },
                ]}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <Styles.ButtonClear
                  fullWidth={false}
                  onClick={() => customModal.close()}
                >
                  Voltar
                </Styles.ButtonClear>
                <Styles.ButtonFilter fullWidth={false} type="submit">
                  Confirmar Dados e Gerar Senha
                </Styles.ButtonFilter>
              </div>
            </Grid>
          </Grid>
        </FormHolder>
      </Styles.Modal>
    );
  }
  function getDoorKey(value) {
    customModal.setInfos(
      null,
      "Visualizar Senha de Fechadura",
      ["Para visualizar a senha, mantenha pressionado o ícone ao lado:"],
      { label: "Voltar", onClick: () => customModal.close() },
      null,
      <Styles.Modal>
        <PasswordModal data={value} />
      </Styles.Modal>
    );
  }
  function openLink(link) {}

  function openView(values) {
    customModal.setInfos(
      null,
      "Editar Dados da Reserva",
      ["Edite e confirme os dados do hóspede:"],
      null,
      null,
      <EditModal values={values} />
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Filters submitFilter={submitFilter} isFetching={isFetching} />
      </Grid>
      <Grid item xs={12}>
        <Styles.Container style={{ marginBlock: "8px" }}>
          <CustomText fontFamily={fonts.bold} fontSize={2}>
            Reservas
          </CustomText>
          {requestedReservationsData?.content?.length > 0 ? (
            <>
              <div style={{ overflow: "auto" }}>
                <Table
                  data={requestedReservationsData?.content}
                  id="historicTable"
                  headers={{
                    table: [
                      "Data Criação da Reserva",
                      "Nome do Hóspede",
                      "Check-in",
                      "Check-out",
                      "Código de Reserva",
                      "Celular",
                      "Disposição Camas",
                      "Ficha de Hóspede",

                      //"Ação",
                    ],
                  }}
                  placeholderSize={7}
                  columnSize={5}
                  loading={isFetching}
                  renderItemColumns={(item) => [
                    item?.createdAt
                      ? filters.formatDateTable(item?.createdAt)
                      : "-",
                    <>
                      {(item?.name ? item?.name : "") +
                        " " +
                        (item?.lastName ? item?.lastName : "")}
                      {/* <Styles.EditButton onClick={() => openView(item)}>
                        <Edit />
                      </Styles.EditButton> */}
                    </>,
                    item?.checkIn
                      ? filters.formatDateTable(item?.checkIn)
                      : "-",
                    item?.checkOut
                      ? filters.formatDateTable(item?.checkOut)
                      : "-",
                    item.reservationCode,
                    item?.celphone ? item?.celphone : "-",

                    item?.bedType
                      ? item?.bedType === "single"
                        ? "02 Solteiros"
                        : item?.bedType === "couple"
                        ? "01 Casal"
                        : "-"
                      : "-",
                    <Styles.OpenText
                      href={item?.url ? item?.url : ""}
                      target="_blank"
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                        }}
                      >
                        <Styles.Status check={item.confirmed}>
                          {item?.confirmed ? (
                            <Check style={{ fontSize: "14px" }} />
                          ) : (
                            <Close style={{ fontSize: "14px" }} />
                          )}
                        </Styles.Status>
                        <CustomText
                          fontFamily={fonts.bold}
                          fontSize={1.5}
                          style={{ textDecoration: "underline" }}
                        >
                          Abrir
                        </CustomText>
                        <Launch style={{ fontSize: "11px" }} />
                      </div>
                    </Styles.OpenText>,

                    /*<>
                    <DropdownActionSelect
                      options={[
                        {
                          name: "Gerar Senha de Fechadura",
                          icon: () => <Icons.keyPlus />,
                          action: () => putDoorKey(item),
                        },
                        {
                          name: " Visualizar Senha de Fechadura",
                          icon: () => <Icons.eyeDot />,
                          action: () => getDoorKey("ABC12"),
                        },
                      ]}
                    />
                  </>, */
                  ]}
                />{" "}
              </div>
              <Pagination
                page={page}
                setPage={changePage}
                totalPages={
                  requestedReservationsData?.totalPages
                    ? requestedReservationsData?.totalPages
                    : 1
                }
              />
            </>
          ) : (
            <CustomText>Sem Dados</CustomText>
          )}
        </Styles.Container>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { requestedReservationsData, isFetching, lastFilter } =
    state.BeHomeRequestedReservations;
  return {
    isFetching,
    requestedReservationsData,
    lastFilter,
  };
}
function mapDispatchToProps(dispatch) {
  const { reservationsRequest } = Creators;
  return {
    reservationsRequest: function (data) {
      return dispatch(reservationsRequest(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Container);
